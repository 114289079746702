<template>

  <div id='building' >

  <div>
    <div style="padding: 10px 0;width:330px;margin-top:15px">
      <el-input v-model="search" style="width: 250px;" clearable></el-input>
      <el-button type="primary" style="margin-left: 5px;" @click="load">搜索</el-button>
    </div>

<!--    <el-carousel :interval="5000" indicator-position="inside" height="350px">-->
<!--      <el-carousel-item v-for="item in List" :key="item.id">-->
<!--        <el-image :src="item.img"  style="width: 80%"/>-->
<!--      </el-carousel-item>-->
<!--    </el-carousel>-->
<div style="width:300px; margin-top:20px;margin-bottom: -50px;height: 150px" class="school-el-card">
    <el-carousel :interval="2000" indicator-position="inside" height="150px" width="750px" >
      <el-carousel-item v-for="item in imagesList" :key="item.id">
        <el-image  :src="item.img" style="width:auto; height:150px; margin-left: 0px; border-radius: 20px"
                   @click="$router.push(`/front/detail?id=${item.id}`)">
        </el-image>
      </el-carousel-item>
    </el-carousel>
</div>
    <el-card style="margin-top: 80px;margin-left: -20px;width:340px;text-align: left" class="school-el-card" >
        <div v-for="item in articles" style="padding: 10px 0;border-bottom: 1px dashed #ccc;text-align: left">
          <div style="display: flex">
            <div style="flex: 2">
              <div style="color: #666; font-size: 7px; padding: 10px 0">
                <span style="margin-right: 2px">{{item.author}}</span> |
                <span style="margin-left: 2px">{{ item.time }}</span>
              </div>
              <div style="font-size: 10px;" class="title" @click="$router.push(`/front/detail?id=${item.id}`)">{{item.title}}</div>
              <div style="padding: 10px 0; color: #666; font-size: 7px">
                {{item.description}}
              </div>
              <div style="font-size: 8px">
                <span><i class="el-icon-view"></i>  {{ item.readCount }}</span>
                <span style="margin-left: 10px"><img :src="require('@/assets/点赞.png')" style="width: 12px;"> {{item.praise}}</span>
              </div>
            </div>
            <div style="mso-border-shadow: yes">
              <el-image :src="item.img" style="width: 100px; height: auto;border-radius: 10px"></el-image>
            </div>
          </div>
        </div>

      <div style="margin: 1px 0">
        <el-pagination style="margin-left: -25px"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-sizes="[5, 10, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </el-card>
  </div>
  </div>
</template>

<script>
import request from "@/utils/request";
export default {
  name: "Home",
  data() {
    return {
      search: '',
      List: [],
      imagesList:[],
      articles: [],
      pageNum: 1,
      pageSize: 10,
      total: 0
    };
  },
  created() {
    this.load();
    this.getTopThreeImages();
  },
  methods: {
    getTopThreeImages() {
      request.get("/article/getTopThreeImages").then(res => {
        console.log("Fetched images:", res.data);
        this.imagesList = res.data;
      }).catch(error => {
        console.error("Failed To Fetch images", error);
      });
    },

    load() {
      request.get("/article/page", {
        params: {
          name: this.search,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }).then(res => {
        console.log("数据获取成功", res.data.records);
        this.articles = res.data.records;
        this.total = res.data.total;
      }).catch(error => {
        console.error("Failed To Load articles", error);
      });
    },
   handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.load();
    },
    handleCurrentChange(pageNum) {
      this.pageNum = pageNum;
      this.load();
    }
  }
}
</script>

<style scoped>
.title:hover {
  color: dodgerblue;
}
.el-carousel__item h3 {
  color: #ffffff;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.school-el-card {
  border: 1px solid transparent;
  border-radius: 15px;
  background-color: rgba(255,255,255,0.1);
  box-shadow: 5px 5px 0 0 rgba(0,0,0,0.2);
}

</style>
